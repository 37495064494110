import React from 'react'
import Layout from '../../components/layout'
import ServiceIntroduction from '../../components/serviceIntroduction'
import kaivuriIcon from "../../icons/kaivuriIcon.svg"
import Seo from "../../components/Seo";
import { graphql, useStaticQuery } from "gatsby";


const Maarakennus = (props) => {

const maanrakennusSeoPic = useStaticQuery(graphql`
    query maanrakennusSeoPic {
      image: file(relativePath: { eq: "square/Ylitalo-12.jpg" }) {
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

    return (
        <Layout>
                  <Seo
                    title="Maanrakentaminen | Ylitalon"
                    description="Maanrakentamisen palveluihimme kuuluvat muun muassa kaivinkoneurakointi, jätevesijärjestelmät sekä pihatyöt. Hoidamme myös vaadittavan johtamisen ja logistiikan"
                    keywords={["maanrakennus", "maanrakennustyöt", "maanrakentaminen", "kaivinkoneurakointi", "jätevesijärjestelmät", "pihatyöt", "Lahti", "Lahden talousalue", "Porvoo", "Orimattila", "Hollola"]}
                    url="https://ylitalon.netlify.app/palvelut/maarakennus"
                    image={"https://ylitalon.netlify.app" + maanrakennusSeoPic.image.publicURL}
      />
            <ServiceIntroduction
                to={"/palvelut"}
                src={"square/Ylitalo-12.jpg"}
                title={"Maanrakennus"}
                titleSize={[8, 14]}
                smallTitle1={"Näppärä kalusto"}
                mediumTitle={"Joustavaa maanrakentamista"}
                infoText1={"Ylitalon rakennuspalvelu urakoi Lahden talousalueella sekä hoitaa itse maarakennustyöt, mikä mahdollistaa joustavat aikataulut ja tehokkaan ajankäytön. Toimiimme kuuluu tehtävien maatöiden ja täyttöjen lisäksi maatöiden johtaminen sekä maanrakentamiseen vaadittavan logistiikan hoitaminen."}
                listTitle={"Palveluihimme muun muassa kuuluu:"}
                icon={kaivuriIcon}
                infoText2={"Teemme suurimman osan kaivuutöistä 10t kumitelaisella kaivinkoneella, joka mahtuu ahtaanpiinkin paikkoihin tehosta tinkimättä. Kaivinkoneemme on varustettu monipuolisin lisävarustein ja liikutamme kaluston itse koukkulavaisella kuorma-autolla."}
                linkTo={"_"}
                list={['Perustustyöt', 'Jätevesijärjestelmät', 'Radonsaneeraukset', 'Kunnallisteekniikan liitokset','Salaojat ja sadevesien hallinta', 'Pihatyöt', 'Kiveykset']}
            />
        </Layout>
    )
}

export default Maarakennus
